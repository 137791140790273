import React from 'react';
import { Segment, Grid, Menu, Table, Popup, Button, Loader, Accordion, Label, Modal} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'
import '../styles.css';
import './ProcessInfo.css';
import { ProcessInstance, ProcessDefinition, ProcInfoViewTemplate, ProcInfoViewDataItem, VariableValue, VariablesMap, TaskInstance, Incident, User } from '../models';
import ClipboardButton  from './ClipboardButton';
import BpmnViewer from './BpmnViewer'
import { getBpmRestService } from '../services/BpmRestService';
import { getCurrentUser } from '../services/CurrentUserService';
import { getAppConfig } from '../services/AppConfig';
import { ErrorMessage } from './ErrorMessage';
import { formatDateVar, formatDateTimeS } from './utils';

type PageKind = 'main' | 'tasks' | 'childProcesses' | 'incidents' |'diagram' | 'data';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface ProcessInfoProps {
  procInstId: string;
  activePage?: PageKind;
  history?: any;
  isFromSearch? : boolean;
}

interface ProcessInfoState {
  activePage: PageKind;
  loading: boolean;
  loadError?: Error;
  reloadDisabled?: boolean;
  procInst?: ProcessInstance;
  superProcInst?: ProcessInstance;
  rootProcInst?: ProcessInstance;
  procDef?: ProcessDefinition;
  template?: ProcInfoViewTemplate;
  variables?: VariablesMap;
  tasks?: TaskInstance[];
  childProcesses?: ProcessInstance[];
  incidents?: Incident[];
  diagramXml?: any;
  activityInstances?: any;
  viewContentModal?: {
    caption: string;
    content: any;
  };
  isAdmin?: boolean;
}

const defaultPage: PageKind = 'main';

const procStateToText = (procState: string) => {
  switch (procState) {
    case 'ACTIVE': return 'Active';
    case 'COMPLETED': return 'Completed';
    case 'EXTERNALLY_TERMINATED': return 'Externally terminated';
    case 'INTERNALLY_TERMINATED': return 'Internally terminated';
    default: return '';
  }
}

const taskStateToText = (task: TaskInstance) => {
  if (!task.endTime) {
    return 'Active';
  } else if (task.deleteReason === 'completed') {
    return 'Completed';
  } else {
    return 'Closed automatically';
  }
}

const highlightJson = (json: string) => {
  return json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
    .replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      (match) => {
        let cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        } else {
          cls = 'number';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      });
}

class HtmlElementWrapper extends React.Component<{element: HTMLElement}> {
  ref = React.createRef<HTMLElement>();
  componentDidUpdate() {
    if (!this.ref.current.firstElementChild) {
      this.ref.current.appendChild(this.props.element);
    }
  }
  render(){
    return (
      <span ref={this.ref}/>
    );
  }
}

export class ProcessInfo extends React.Component<ProcessInfoProps, ProcessInfoState> {
  private appConfig = getAppConfig();

  constructor (props: ProcessInfoProps) {
    super(props)
    this.state = {
      activePage: props.activePage || defaultPage, 
      loading: true
    }
  }

  private userDict: {[key: string]: User} = {};

  private async loadUsers(ids: string[]) {
    ids.forEach(async id => {
      if (id && !this.userDict[id]) {
        const user = await getBpmRestService().getUser(id);
        this.userDict[id] = user;
      }
    });
  }

  private async loadMain() {
    if (this.state.procInst) {
      return;
    }
    const procInst = await getBpmRestService().getProcessInstance(this.props.procInstId);
    const procDef = await getBpmRestService().getProcessDefinitionById(procInst.processDefinitionId);
    const viewRes = await getBpmRestService().getProcessInfoViewResource(procInst.processDefinitionId);
    const template = viewRes.template;

    if (procInst.startUserId) {
      await this.loadUsers([procInst.startUserId]);
    }

    let superProcInst: ProcessInstance;
    let rootProcInst: ProcessInstance;
    if (procInst.rootProcessInstanceId && procInst.rootProcessInstanceId !== procInst.id) {
      const parentIsd = [procInst.rootProcessInstanceId];
      if (procInst.superProcessInstanceId && procInst.superProcessInstanceId !== procInst.rootProcessInstanceId) {
        parentIsd.push(procInst.superProcessInstanceId);
      }
      const parentProcInsts = await getBpmRestService().getProcessInstances(parentIsd);
      superProcInst = parentProcInsts.find(item => item.id === procInst.superProcessInstanceId);
      rootProcInst = parentProcInsts.find(item => item.id === procInst.superProcessInstanceId);
    }

    const userGroups = await getCurrentUser().getUserGroups();
    const adminGroups = ['camunda-admin', 'monitor', 'std-'+procDef.key+'-admin', 'std-'+procDef.key+'-monitor'];
    const isAdmin = !!userGroups?.find(g => adminGroups.includes(g.id));

    this.setState({procInst, procDef, template, superProcInst, rootProcInst, isAdmin});
  }

  private async loadData() {
    if (this.state.variables) {
      return;
    }
    if (!this.state.isAdmin) {
      return;
    }
    const dataView = this.state.template?.dataView;
    const varNames: string[] = dataView ? dataView.sections
      .map(sect => sect.items)
      .filter(items => items)
      .flat()
      .map(item => item.variable)
      .filter(vars => vars)
      .flat()
      .sort()
      .filter((s, i, arr) => i === 0 || s !== arr[i - 1])
      : null;
    const variables = await getBpmRestService().getProcessInstanceVariables(this.state.procInst.id, varNames);
    this.setState({variables})
  }

  private async loadTasks() {
    if (this.state.tasks) {
      return;
    }
    const tasks = await getBpmRestService().getProcessInstanceTasks(this.props.procInstId);
    await this.loadUsers(tasks.map(task => task.assignee).filter(s => s));
    this.setState({tasks})
  }

  private async loadChildProcesses() {
    if (this.state.childProcesses) {
      return;
    }
    const childProcesses = await getBpmRestService().getChildProcessInstances(this.props.procInstId);
    this.setState({childProcesses})
  }

  private async loadDiagram() {
    if (this.state.activityInstances) {
      return;
    }
    const diagramXml = await getBpmRestService().getProcessDefinitionXml(this.state.procInst.processDefinitionId);
    const activityInstances = await getBpmRestService().getActivityInstances(this.props.procInstId);
    this.setState({diagramXml, activityInstances});
  }

  private async loadIncidents() {
    if (this.state.incidents) {
      return;
    }
    const incidents = await getBpmRestService().getProcessInstanceIncidents(this.props.procInstId);
    this.setState({incidents})
  }

  private async load() {
    try {
      try {
        await this.loadMain();
      } finally {
        this.setState({loading: false})
      }
  
      if(this.state.activePage === 'data') {
        await this.loadData();
      }
      
      await this.loadIncidents();
      
      if(this.state.activePage === 'diagram') {
        await this.loadDiagram();
      }
      
      await this.loadTasks();

      await this.loadChildProcesses();

      this.setState({loadError: null});
    } catch (e) {
      this.setState({loadError: e});
    }
  }

  private async reload() {
    await (async () => {
      this.setState({
        procInst: null,
        template: null,
        variables: null,
        tasks: null,
        diagramXml: null,
        activityInstances: null,
        incidents: null,
        loading: true,
        loadError: null,
        reloadDisabled: true
      });
    })(); 
    try {
      await this.load();
    } finally {
      setTimeout(() => {this.setState({reloadDisabled: false})});
    }
  }

  private async openPage(page: PageKind) {
    this.setState({activePage: page});
    this.load();
  }

  async componentDidMount() {
    this.load()
  }

  async componentDidUpdate(prevProps: ProcessInfoProps) {
    if (this.props.activePage === prevProps.activePage && this.props.procInstId === prevProps.procInstId) {
      return;
    }
    this.setState({activePage: this.props.activePage || defaultPage});
    if (this.props.procInstId !== prevProps.procInstId) {
      this.reload()
    } else {
      this.load()
    }
  }

  private formatUserName(id: string): string {
    const user = id && this.userDict[id]
    const userName = user ? (user.firstName + (user.lastName ? ' ' +  user.lastName : '')) : '';
    return userName && userName !== id ? `${userName} (${id})` : id;
  }
    
  private renderMainInfoItem(item: {lab: any, val: any, withClipboard?: boolean, clipboardText?: string, clipboardHtml?: string}) {
    return (
      <Table.Row key={item.lab} >
        <Table.Cell className="table-label">{item.lab}</Table.Cell>
        <Table.Cell>
          {item.val !== undefined && item.val != null ? item.val : "-"}
          {(item.clipboardText || (item.withClipboard && item.val)) &&
            <ClipboardButton text={item.clipboardText || item.val} html={item.clipboardHtml}/>}</Table.Cell>
      </Table.Row>
    )
  }

  private renderSysInfo() {
    if(!this.state.procInst) {
      return (<Loader active />)
    }
    const {procDef, procInst, superProcInst, rootProcInst} = this.state;
    return (
      <>
        <Table compact celled>
          <Table.Body>
            {this.renderMainInfoItem({lab: 'Definition key', val: procDef.key, withClipboard: true})}
            {this.renderMainInfoItem({lab: 'Instance ID', val: procInst.id, withClipboard: true})}
            {this.renderMainInfoItem({lab: 'Business key', val: procInst.businessKey, withClipboard: true})}
            {this.renderMainInfoItem({lab: 'Initiator', val: this.formatUserName(procInst.startUserId), withClipboard: true})}
            {this.renderMainInfoItem({lab: 'Start date', val: formatDateTimeS(procInst.startTime), withClipboard: true})}
            {this.renderMainInfoItem({lab: 'State', val: procStateToText(procInst.state), withClipboard: true})}
            {procInst.endTime && this.renderMainInfoItem({lab: 'End date', val: formatDateTimeS(procInst.endTime), withClipboard: true})}
            {
              procInst.superProcessInstanceId &&
                this.renderMainInfoItem({lab: 'Parent process',
                  val: superProcInst ?
                    <a target='_blank' rel='noopener noreferrer' href={`#/process-info/${superProcInst.id}`}>{superProcInst.processDefinitionName}</a> :
                    procInst.superProcessInstanceId,
                  clipboardText: superProcInst ?
                   `${window.location.href.split('#')[0]}#/process-info/${superProcInst.id}` :
                    procInst.superProcessInstanceId,
                  clipboardHtml: superProcInst ?
                    `<a target="_blank" rel="noopener noreferrer" href="${window.location.href.split('#')[0]}#/process-info/${superProcInst.id}">${superProcInst.processDefinitionName}</a>` :
                    procInst.superProcessInstanceId
                })
            }
            {
              procInst.rootProcessInstanceId &&  procInst.rootProcessInstanceId !== procInst.id && procInst.rootProcessInstanceId !== procInst.superProcessInstanceId &&
                this.renderMainInfoItem({lab: 'Main process',
                  val: rootProcInst ?
                    <a target='_blank' rel='noopener noreferrer' href={`#/process-info/${rootProcInst.id}`}>{rootProcInst.processDefinitionName}</a> :
                      procInst.rootProcessInstanceId,
                  clipboardText: rootProcInst ?
                    `${window.location.href.split('#')[0]}#/process-info/${rootProcInst.id}` :
                    procInst.rootProcessInstanceId,
                  clipboardHtml: rootProcInst ?
                    `<a target="_blank" rel="noopener noreferrer" href="${window.location.href.split('#')[0]}#/process-info/${rootProcInst.id}">${rootProcInst.processDefinitionName}</a>` :
                      procInst.rootProcessInstanceId
                })
            }
          </Table.Body>
        </Table>
      </>
    )
  }

  private renderViewContentBtn(caption: string, text: string) {
    return (
      <Popup
        trigger={
          <Button
            icon='eye'
            size='mini'
            onClick={() => {
              this.setState({
                viewContentModal: {
                  caption: caption,
                  content: (<pre className='object-source' dangerouslySetInnerHTML={{__html: highlightJson(text)}}/>)
                }
              })
            }}
          />
        }
        mouseEnterDelay={500}
        content='View content'
        position='top center'
        size="mini"
        inverted        
      />
    )
  } 

  private renderVarValue(val: VariableValue, label: string) {
    if (!val) {
      return '-';
    }
    switch (val.type) {
      case 'Null':
        return '-';
      case 'Boolean':
        return val.value ? 'Yes' : 'No';
      case "Date": {
        const text = formatDateVar(val.value);
        return (
          <>
            <span>{text}</span>
            <ClipboardButton text={text}/>
          </>
        )
      }
      case "Json":
      case "Object": {
        const text = JSON.stringify(val.value, undefined, 2);
        return (
          <>
            {this.renderViewContentBtn(label, text)}
            <span className='object-text'>{text}</span>
            <ClipboardButton text={text}/>
          </>
        );
      }
      default:{
        const text = val.value;
        return (
          <>
            <span>{text}</span>
            <ClipboardButton text={text}/>
          </>
        );
      }
    }
  }

  private renderDataWithTemplate(item: ProcInfoViewDataItem) {
    const values = this.state.variables;
    let content: JSX.Element;
    if (item.formatter) {
      const data: VariablesMap = {};
      (item.variable || []).forEach(varName => {
        data[varName] = values[varName];
      });
      const formatted = item.formatter(data);
      if (formatted instanceof HTMLElement) {
        content = <HtmlElementWrapper element={formatted}/>
      } else {
        content = <>{formatted}</>
      }
    } else {
      content = <div className='var-value'>{ this.renderVarValue(values[(item.variable || [])[0]], item.label) }</div>;
    }
    return content;
  }

  private renderData() {
    if(!this.state.variables) {
      return (<Loader active />)
    }
    if (this.state.template?.dataView) {
      return (
        <Accordion exclusive={false} fluid
          className="data-sections"
          defaultActiveIndex={this.state.template?.dataView?.sections?.map((_, i) => i)}
          panels={(this.state.template?.dataView?.sections || []).map((section, i) => (
            {
              key: i,
              title: section.header,
              content: (
                <Table compact celled fixed>
                  <Table.Body>
                    {(section.items || []).map((item, i) => 
                      <Table.Row key={i} >
                        <Table.Cell className='table-label'>{item.label}</Table.Cell>
                        <Table.Cell className='table-data'>
                          {this.renderDataWithTemplate(item)}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              )
            }
          ))}
        />
      )
    } else {
      return (
        <Table compact celled fixed>
          <Table.Body>
            {(Object.entries(this.state.variables) || []).map(entry => 
              <Table.Row key={entry[0]}>
                <Table.Cell className='table-label'>{entry[0]}</Table.Cell>
                <Table.Cell className='table-data'>
                  <div className='var-value'>{this.renderVarValue(entry[1], entry[0])}</div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )
    }
  }

  private renderMain() {
    return (
      <>
        { this.renderSysInfo() }
      </>
    )
  }

  renderTasks() {
    if(!this.state.tasks) {
      return (<Loader active />)
    }
    return (
      <>
        <Table compact celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Task</Table.HeaderCell>
              <Table.HeaderCell>Assignee/Performer</Table.HeaderCell>
              <Table.HeaderCell>Create Date</Table.HeaderCell>
              <Table.HeaderCell>End Date</Table.HeaderCell>
              <Table.HeaderCell>State</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.tasks.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  No tasks in this process.
                </Table.Cell>
              </Table.Row>
            ) : this.state.tasks.map(item => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <a href={`#/task/${item.id}${item.endTime?'?historic=true':''}`} target='_blank' rel="noopener noreferrer">{item.name}</a>
                  <ClipboardButton 
                    text={`${window.location.href.split('#')[0]}#/task/${item.id}`}
                    html={`<a target="_blank" rel="noopener noreferrer" href="${window.location.href.split('#')[0]}#/task/${item.id}${item.endTime?'?historic=true':''}">${item.name}</a>`}
                  />
                </Table.Cell>
                <Table.Cell>
                  {((text)=><><span>{text}</span><ClipboardButton text={text}/></>)(this.formatUserName(
                    item.endTime ? item.performer : item.assignee))}
                </Table.Cell>
                <Table.Cell>{formatDateTimeS(item.startTime)||'-'}</Table.Cell>
                <Table.Cell>{formatDateTimeS(item.endTime)||'-'}</Table.Cell>
                <Table.Cell>{taskStateToText(item)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    )
  }

  renderChildProcesses() {
    if(!this.state.childProcesses) {
      return (<Loader active />)
    }
    return (
      <>
        <Table compact celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Process Name</Table.HeaderCell>
              <Table.HeaderCell>Business key</Table.HeaderCell>
              <Table.HeaderCell>Process State</Table.HeaderCell>
              <Table.HeaderCell>Start Date</Table.HeaderCell>
              <Table.HeaderCell>End Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.childProcesses.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={5}>
                No subprocesses in this process.
                </Table.Cell>
              </Table.Row>
            ) : this.state.childProcesses.map(item => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <a target='_blank' rel='noopener noreferrer' href={`#/process-info/${item.id}`}>{item.processDefinitionName}</a>
                  <ClipboardButton
                    text={`${window.location.href.split('#')[0]}#/process-info/${item.id}`}
                    html={`<a target="_blank" rel="noopener noreferrer" href="${window.location.href.split('#')[0]}#/process-info/${item.id}">${item.processDefinitionName}</a>`}
                  />
                </Table.Cell>
                <Table.Cell>
                  {item.businessKey || '-'}
                </Table.Cell>
                <Table.Cell>{procStateToText(item.state)}</Table.Cell>
                <Table.Cell>{formatDateTimeS(item.startTime)||'-'}</Table.Cell>
                <Table.Cell>{formatDateTimeS(item.endTime)||'-'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    )
  }

  renderIncidents() {
    if(!this.state.incidents) {
      return (<Loader active />)
    }
    return (
      <>
        <Table compact celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Occurrence time</Table.HeaderCell>
              <Table.HeaderCell>Error message</Table.HeaderCell>
              <Table.HeaderCell>Activity</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.incidents.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  No incidents in this process.
                </Table.Cell>
              </Table.Row>
            ) : this.state.incidents.map(item => (
              <Table.Row key={item.id}>
                <Table.Cell>{formatDateTimeS(item.incidentTimestamp)}</Table.Cell>
                <Table.Cell>{item.incidentMessage}</Table.Cell>
                <Table.Cell>{item.activityId}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    )
  }

  renderDiagram() {
    if(!this.state.diagramXml) {
      return (<Loader active />)
    }
    return (
      <BpmnViewer
        xml={this.state.diagramXml}
        activity={this.state.activityInstances}
        incidents={this.state.incidents}
        loading={false}
      />
    )
  }

  renderPage(activePage: PageKind) {
    switch (activePage) {
      case 'main': return this.renderMain();
      case 'data': return this.renderData();
      case 'tasks': return this.renderTasks();
      case 'childProcesses': return this.renderChildProcesses();
      case 'incidents': return this.renderIncidents();
    }
  }

  renderHeader() {
    const {procInst} = this.state;
    if (!procInst) {
      return (
        <Loader active size='tiny' inline />
      )
    }
    const text = `${procInst.processDefinitionName} (${procInst.id})`;
    return (
      <>
        {procInst.processDefinitionName}
        <ClipboardButton
          text={`${window.location.href.split('#')[0]}#/process-info/${procInst.id}`}
          html={`<a target="_blank" rel="noopener noreferrer" href="${window.location.href.split('#')[0]}#/process-info/${procInst.id}">${text}</a>`}
        />
      </>
    )
  }

  render() {
    if(this.state.loading) {
      return (<Loader active />)
    }
    return (
      <>
        <div className='app-toolbar'>
          <div style={{width: '100%'}}>
            {this.props.isFromSearch && this.props.history && 
              <Popup
                trigger={
                  <Button circular basic size='medium' icon='arrow left' className='app-toolbar-btn btn-level-up'
                    onClick={ () => { this.props.history.goBack() } }
                  />
                }
                content='Back to search parameters'
                position='bottom left'
                size="tiny"
                inverted        
              />
            }
            <Popup
              trigger={
                <Button circular basic icon='refresh' size='medium' className='app-toolbar-btn'
                  disabled={ this.state.reloadDisabled }
                  loading={ this.state.loading }
                  onClick={ () => { this.reload() } }
                />
              }
              content='Refresh info'
              position='bottom left'
              size="tiny"
              inverted      
            />
            <div className='app-page-header'>
              {this.renderHeader()}
            </div>
          </div>
        </div>

        <ErrorMessage error={this.state.loadError}/>

        <Grid>

          <Grid.Column className='menu-panel'>
            <Menu fluid vertical tabular>
              <Menu.Item active={ this.state.activePage === 'main' } onClick={()=>{this.openPage('main')}}>
                Main info
              </Menu.Item>
              <Menu.Item active={ this.state.activePage === 'tasks' } onClick={()=>{this.openPage('tasks')}}>
                User tasks
                {this.state.tasks ? (
                  <Label color='teal'>{this.state.tasks.length}</Label>
                ) : (
                  <Label size='mini' color='teal'><Loader active size='mini' inline inverted/></Label>
                )}
              </Menu.Item>
              <Menu.Item active={ this.state.activePage === 'childProcesses' } onClick={()=>{this.openPage('childProcesses')}}>
                Subprocesses
                {this.state.childProcesses ? (
                  <Label color='teal'>{this.state.childProcesses.length}</Label>
                ) : (
                  <Label size='mini' color='teal'><Loader active size='mini' inline inverted/></Label>
                )}
              </Menu.Item>
              { this.state.isAdmin &&
              <Menu.Item active={ this.state.activePage === 'incidents' } onClick={()=>{this.openPage('incidents')}}>
                Incidents
                {this.state.incidents ? (
                  <Label color={this.state.incidents.length > 0 ? 'red' : 'teal'}>{this.state.incidents.length}</Label>
                ) : (
                  <Label size="mini" color='teal'><Loader active size='mini' inline inverted/></Label>
                )}
              </Menu.Item>
              }
              { this.state.isAdmin &&
              <Menu.Item active={ this.state.activePage === 'data' } onClick={()=>{this.openPage('data')}}>
                Process data
              </Menu.Item>
              }
              <Menu.Item active={ this.state.activePage === 'diagram' } onClick={()=>{this.openPage('diagram')}}>
                Diagram
              </Menu.Item>
            </Menu>
          </Grid.Column>

          <Grid.Column className={this.state.activePage === 'diagram' ? 'diagram-panel' : 'content-panel'} stretched>
            {this.state.activePage !== 'diagram' && 
              <Segment>
                { this.renderPage(this.state.activePage) }
              </Segment>
            }
            <Segment  style={{display: this.state.activePage === 'diagram' ? 'block' : 'none', height: '100%'}}>
              { this.renderDiagram() }
            </Segment>
          </Grid.Column>

        </Grid>

        <Modal
          open={this.state.viewContentModal !== undefined}
          closeIcon
          closeOnEscape
          closeOnDimmerClick
          closeOnDocumentClick
          onClose={() => this.setState({viewContentModal: undefined})}
        >
          <Modal.Header icon='archive' content={this.state.viewContentModal?.caption}/>
          <Modal.Content scrolling>
            {this.state.viewContentModal?.content}
          </Modal.Content>
        </Modal>

      </>
    )
  }
}

